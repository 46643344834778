import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import CaptainSlider from "./CaptainSlider";
import AppScreenSlider from "./AppScreenSlider";
import CumminitySlider from "./CumminitySlider";
import { Link } from "react-router-dom";
import Footer from "../commoncomponents/Footer";
import { useSubscribeMutation } from "../services/Post";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import { Tooltip } from "bootstrap";
import Header from "../commoncomponents/Header";
import { useTranslation } from "react-i18next";
import MapWithMarker from "../map/MapWithMarker";

function Index() {
  const [subscribe, { isLoading: loading }] = useSubscribeMutation();

  const [selectedLanguage, setSelectedLanguage] = useState("en");

  console.log("selectedLanguage", selectedLanguage);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "en";
    setSelectedLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    toast.success(
      `Language changed to ${selectedLanguage === "gr" ? "English" : "Greek"}`
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm();

  const handleSubscribe = async (info) => {
    try {
      const res = await subscribe({
        email: info?.email,
        language: selectedLanguage === "gr" ? "Greek" : "English",
      });

      if (!res?.data?.error) {
        toast.success(res.data.message || "You have successfully subscribed!");
        reset();
      } else {
        toast.error(
          res.data.message || "Subscription failed. Please try again."
        );
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      console.error("Subscription error:", error);
    }
  };

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const handleDownloadClick = () => {
    // Handle download button click here
    console.log("Download button clicked");
  };

  const scrollToSection = (event) => {
    event.preventDefault();
    document.getElementById("buttonclose").click();
    document
      .getElementById("howitworkss")
      .scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSectionbannerpart = (event) => {
    event.preventDefault();
    document.getElementById("buttonclose").click();
    document
      .getElementById("banner_part")
      .scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSectionsetting = (event) => {
    event.preventDefault();
    document.getElementById("buttonclose").click();
    document.getElementById("Settings").scrollIntoView({ behavior: "smooth" });
  };

  const moveMap = (direction) => {
    const map = document.querySelector(".map_box");
    const moveDistance = 50;
    const currentScrollY = window.scrollY;

    if (direction === "up") {
      map.scrollTop -= moveDistance;
      window.scrollTo(0, currentScrollY - moveDistance);
    } else if (direction === "down") {
      map.scrollTop += moveDistance;
      window.scrollTo(0, currentScrollY + moveDistance);
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <header className="header_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-10 col-10">
              <div className="header_left">
                <Link to="/">
                  {/* <img src="/assets/resources/logo.svg" alt="" /> */}
                  <img src={require("../assets/img/logo.png")} alt="" />
                </Link>
                <div className="languagee">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedLanguage}
                    onChange={handleChangeLanguage}
                  >
                    <option value="en">{t("EN")}</option>
                    <option value="gr">{t("GR")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-lg-block d-md-none d-none">
              <div className="header_menus">
                <ul>
                  <li>
                    <Link
                      className="active"
                      to="#"
                      id="banner_part"
                      onClick={scrollToSectionbannerpart}
                    >
                      {t("Home")}
                    </Link>
                  </li>
                  <li>
                    <a href="index.html#howitworkss" onClick={scrollToSection}>
                      {t("How It Works")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="index.html#Settings"
                      onClick={scrollToSectionsetting}
                    >
                      {t("About Product")}
                    </a>
                  </li>
                  <li>
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      // title="info@athlopolis.com"
                      to="/contact-us"
                    >
                      {t("Contact")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-2 col-2 text-lg-start text-md-end text-end">
              <div className="social_box">
                <Link
                  className="social_ico"
                  to="https://www.instagram.com/athlopolis.sport/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/insta.svg" alt="instagram" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.facebook.com/profile.php?id=61555006059082"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/facebook.svg" alt="facebook" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/linkden.svg"
                    alt="linkedin"
                    style={{ height: "41px" }}
                  />
                </Link>
                <Link
                  className="social_ico"
                  to={`https://x.com/home?lang=${selectedLanguage}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/X.svg"
                    alt="tweet"
                    style={{ height: "41px" }}
                  />
                </Link>
                <div className="dropdown header_dropdown d-none">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/img/dots.svg" alt />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobile_menuss">
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="assets/img/menu-4-line.svg" alt />
                </a>
                <div
                  className="offcanvas offcanvas-start visible"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                      <img src="assets/img/logo.png" alt />
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      id="buttonclose"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="header_menus">
                      <ul>
                        <li>
                          <Link
                            className="active"
                            to="#"
                            id="banner_part"
                            onClick={scrollToSectionbannerpart}
                          >
                            {t("Home")}
                          </Link>
                        </li>
                        <li>
                          <a
                            href="index.html#howitworkss"
                            onClick={scrollToSection}
                          >
                            {t("How It Works")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#Settings"
                            onClick={scrollToSectionsetting}
                          >
                            {t("About Product")}
                          </a>
                        </li>
                        <li>
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            // title="info@athlopolis.com"
                            to="/contact-us"
                          >
                            {t("Contact")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="social_box">
                      <Link
                        className="social_ico"
                        to="https://www.instagram.com/athlopolis.sport/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/insta.svg" alt="instagram" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.facebook.com/profile.php?id=61555006059082"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/facebook.svg" alt="facebook" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/linkden.svg" alt="linkedin" />
                      </Link>
                      <Link
                        className="social_ico"
                        to={`https://x.com/home?lang=${selectedLanguage}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/X.png" alt="tweet" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Header /> */}
      <section className="soonpart">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-md-start justify-content-center">
            <div className="col-md-3 d-md-block d-none" />
            <div className="col-md-6">
              <div className="soonpart_content text-center">
                <p>{t("Athlopolis soon available on all devices!")}</p>
              </div>
            </div>
            <div className="col-md-3 text-md-end text-center mt-md-0 mt-2">
              <Link className="Become_Partner" to="/partner">
                {t("Become a Partner")}{" "}
                <img src="assets/img/arrow_forward_ios.svg" alt />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="banner_part" className="banner_part">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-6">
              <div className="banner_img text-center">
                <img src="assets/img/mobile.svg" alt />
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner_content">
                <h2>
                  {t("CONNECT")} &amp; <br />
                  {t("Play anywhere")}
                </h2>
                <p>
                  {t(
                    "Join Athlopolis today, we are the #1 padel community in Greece."
                  )}
                </p>
                <form
                  className="emailbox"
                  action=""
                  onSubmit={handleSubmit(handleSubscribe)}
                >
                  <div className="form-group position-relative">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={classNames(
                        "form-control border border-secondary ",
                        {
                          "is-invalid": errors?.email,
                        }
                      )}
                      placeholder={t("Enter your email")}
                      {...register("email", {
                        required: t("Email is Required*"),
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t("Invalid email address"),
                        },
                      })}
                    />
                    <button
                      className="getstarted border-0"
                      type={loading ? "button" : "submit"}
                      disabled={loading}
                      style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    >
                      {loading ? (
                        <>
                          {t("Starting")}{" "}
                          <Spinner
                            style={{
                              fontSize: "2rem",
                              verticalAlign: "middle",
                            }}
                            className="ms-2"
                          />
                        </>
                      ) : (
                        t("Get Started")
                      )}
                    </button>
                  </div>
                  {errors?.email && (
                    <small className="errorText mx-1 fw-bold text-danger mt-1">
                      {errors?.email?.message}
                    </small>
                  )}
                </form>
                <div className="download_btn">
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://apps.apple.com/us/app/athlopolis/id6618139109"
                    // onClick={handleDownloadClick}
                    target="_blank"
                  >
                    <img src="assets/img/appstore.svg" alt="" />
                  </Link>
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://play.google.com/store/apps/details?id=com.athlopolis"
                    // onClick={handleDownloadClick}
                    target="_blank"
                  >
                    <img src="assets/img/google-play.svg" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CaptainSlider />
      <section className="commanservices">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="comman_head text-center">
                <h2>{t("Built for padel enthusiasts!")}</h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/service_1.png" alt />
                <h3>{t("Connect")}</h3>
                <p>
                  {t(
                    "Find open matches in your area or match with other players with similar ages and levels"
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/service_2.png" alt="" />
                <h3>{t("Book")}</h3>
                <p>
                  {t("Schedule")} &amp;{" "}
                  {t(
                    "Book venues in your area in a few steps,it takes less than a minute to find and book a venue"
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/service_3.png" alt />
                <h3>{t("Play")}</h3>
                <p>
                  {t("Meet")} &amp;{" "}
                  {t(
                    "Play with other Padel players, connect, build friendships and join tournaments together"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="howitworkss" className="howitworkss">
        <div className="container-fluid">
          <div
            className="howitworkss_box row"
            style={{ backgroundImage: 'url("assets/img/howitwork_bg1.png")' }}
          >
            <div className="col-md-6">
              <div className="howitworkss_content">
                <span>{t("Join or Create")}</span>
                <h2>
                  {t("Find a")} <strong>{t("match")}</strong> &amp; <br />
                  {t("Book within seconds")}
                </h2>
                <p>
                  {t(
                    "Join daily matches and meet with players at your level, Customize your own match and invite others!"
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 order-changed">
              <div className="howitworkss_img text-center">
                <img
                  src="assets/img/joincreates.png"
                  alt=""
                  style={{ height: "100%", width: "392px" }}
                />
              </div>
            </div>
          </div>
          <div
            className="howitworkss_box row"
            style={{ backgroundImage: 'url("assets/img/howitwork_bg.png")' }}
          >
            <div className="col-md-6">
              <div className="howitworkss_img text-center">
                <img
                  src="assets/img/allvenuess.png"
                  // src="assets/img/howitwork.svg"
                  alt=""
                  style={{ height: "100%", width: "392px" }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="howitworkss_content">
                <span>{t("Venue list")}</span>
                <h2>
                  {t("Explore venues with")} <br />
                  {t("5 different")} <strong>{t("Filters")}</strong>
                </h2>
                <p>
                  {t(
                    "Our filters allow you to find the perfect venue for you, Within 2km? Panoramic? 5 Star ratings? Budget friendly? Maybe a discount even? We got you covered!"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="howitworkss_box row"
            style={{ backgroundImage: 'url("assets/img/howitwork_bg1.png")' }}
          >
            <div className="col-md-6">
              <div className="howitworkss_content">
                <span>{t("Player searching")}</span>
                <h2>
                  <strong>{t("Search")}</strong> {t("for Players")} &amp;{" "}
                  {t("Padel together")}
                </h2>
                <p>
                  {t(
                    "We give you different filtering options so you can find the type of players you want to padel with!"
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 order-changed">
              <div className="howitworkss_img text-center">
                <img
                  src="assets/img/playersearching.png"
                  alt=""
                  style={{ height: "100%", width: "392px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="athlopolis_cumminity">
        <div className="row">
          <div className="col-md-5 pe-0">
            <div className="cumminity_img">
              <img src="assets/img/map_2.png" alt />
            </div>
          </div>
          <div className="col-md-7 ps-0">
            <CumminitySlider />
          </div>
        </div>
      </section>
      <section id="Settings" className="app_screen">
        <AppScreenSlider />
        <div className="container-small">
          <div className="commaper_with">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th />
                    <th>{t("Athlopolis")}</th>
                    <th>{t("Competitors")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Book Padel Venues")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Joining / Creating Match")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Messages / Chats")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Padel Levels")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("Live Matching Making")}
                      <p>
                        {t(
                          "Find open matches in your area or match with other players with similar ages and levels."
                        )}
                      </p>
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("Events")}
                      <p>
                        {t(
                          "Join a variety of events such as Tournaments or organize your own and invite family and friends."
                        )}
                      </p>
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt=""
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt=""
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="athlopolis_venus">
        <div className="row">
          <div className="col-md-12">
            <div className="commanhead1 text-center">
              <span>{t("CLOSE TO YOU")}</span>
              <h2>{t("Athlopolis Venues")}</h2>
            </div>
          </div>
          <div className="col-12">
            <div className="map_box">
              <MapWithMarker />
            </div>
          </div>
        </div>
      </section> */}
      <section className="athlopolis_venus">
        <div className="row">
          <div className="col-md-12">
            <div className="commanhead1 text-center">
              <span>{t("CLOSE TO YOU")}</span>
              <h2>{t("Athlopolis Venues")}</h2>
            </div>
          </div>
          <div className="col-12">
            <div
              className="map_box"
              style={{ position: "relative", overflow: "hidden" }}
            >
              {/* <img src="assets/img/map.png" alt /> */}
              <MapWithMarker />
              <button
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
                onClick={() => moveMap("up")}
              >
                ↑
              </button>
              <button
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
                onClick={() => moveMap("down")}
              >
                ↓
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="downloadapotholis">
        <div className="container-small">
          <div className="row align-items-center">
            <div className="col-md-6 mb-md-0 mb-4">
              <div className="downloadapotholis_img">
                <img src="assets/img/downloads.svg" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="apotholis_content ps-lg-3">
                <h2>
                  {t("DOWNLOAD THE")} <span>{t("ATHLOPOLIS")}</span> {t("APP")}
                </h2>
                <p>
                  {t(
                    "Enjoy the newly released App for padel matching. Download the app and find your first match now!"
                  )}
                </p>
                {/* <a
                  className="download_atho"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Coming Soon"
                  href="javascript:void(0)"
                >
                  {t("Download Athlopolis")}
                </a> */}
                <div className="appdownload justify-content-start">
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://apps.apple.com/us/app/athlopolis/id6618139109"
                    target="_blank"
                  >
                    <img src="assets/img/appstore.svg" alt />
                  </Link>
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://play.google.com/store/apps/details?id=com.athlopolis"
                    target="_blank"
                  >
                    <img src="assets/img/google-play.svg" alt />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer selectedLanguage={selectedLanguage} />
    </div>
  );
}

export default Index;
